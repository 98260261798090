.faceCheck {
  display: flex;
  align-items: center;
  padding: 0 0.675rem;
}
.checkStyle {
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-end;
  overflow: auto;
}
.checkStyleBox {
  position: relative;
  margin: 0 5px;
}
.checkStyleBox .el-checkbox {
  position: absolute;
  top: -5px;
  left: 0;
  z-index: 22;
}
.checkStyleBox .modelBox {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 25;
  width: 100%;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.3);
}
.checkStyleBox .modelBox .tipMsg {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 15px;
  color: #fff;
  font-size: 12px;
}
.getMuch {
  color: #6676ff;
  cursor: pointer;
}
.el-table--border {
  border-bottom: 1px solid #ebeef5;
}
